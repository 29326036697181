:root {
  --primary-color: #2e4374;
  --secondary-color: #4b527e;
  --third-color: #7c81ad;
  --fourth-color: #28397e;
  --fifth-color: #010528;
  --sixth-color: #e8cfb8;
  --seventh-color: #e5c3a6;
}

.discord-button {
  background-color: #7289da;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: border 0.3s;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid white;
    background-color: #7289da;
  }
}
.riot-button {
  background-color: #ff0000;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: border 0.3s;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid white;
    background-color: #ff0000;
  }
}

.discord-button:disabled {
  cursor: not-allowed;
  background-color: #ccc;
  border: none;
}
.discord-button-green{
  cursor: not-allowed;
  background-color: #177500;
  border: none;
}
.discord-button-green:disabled {
  cursor: not-allowed;
  background-color: #177500;
  border: none;
}
.text-white {
  color: white;
}

.blur {
  filter: blur(5px);
}

.myGradient {
  background: linear-gradient(to right, #070720, #1d1d39);
}

.myGradient:hover {
  background: linear-gradient(to right, #070720, #383868);
}

.clamp-5 {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.vertical-bar {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 32px;
  height: 100%;
  background-color: #2e4374;
}
.vertical-bar:hover {
  background-color: #3c558f;
}

.border-gradient-yellow {
  border: 1px solid transparent;
  border-image: linear-gradient(180deg, #ffd700, #919b3c) 1;
  border-image-slice: 1;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.158);
}
.image-overlay {
  position: relative;
  cursor: pointer;

}

.image-overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Fondo negro con opacidad */
  border: 4px solid #FFFF00; /* Borde amarillo */
  border-radius: 50%; /* Forma circular */
  opacity: 0; /* Inicialmente, no visible */
  transition: opacity 0.3s; /* Transición suave */
}

/* Mostrar el overlay y el texto al hacer hover en el contenedor */
.image-overlay:hover::before {
  opacity: 0.5;
}

/* Estilo para el texto */
.placeholder-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-weight: bold;
  color: white;
  z-index: 1; /* Colocar el texto encima del overlay */
  opacity: 0; /* Inicialmente, no visible */
  transition: opacity 0.3s; /* Transición suave */
}

/* Mostrar el texto al hacer hover en el contenedor */
.image-overlay:hover .placeholder-text {
  opacity: 1;
}

/* Ajustes para hacer que la imagen del placeholder ocupe todo el div */
.image-overlay img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fill-hover {
  position: relative;
  overflow: hidden;
}

.fill-hover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #EAB308; /* color equivalent to bg-gray-100 in Tailwind */
  transition: width 0.3s ease;
  z-index: 0;
}

.fill-hover:hover::before {
  width: 100%;
}

.fill-hover > * {
  position: relative;
  z-index: 1;
}